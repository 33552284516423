/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import Divider from '../components/sections/sectionDivider';
import HeadingSection from '../components/sections/headingSection';
import PageIntro from '../components/sections/pageIntro';

const PurchasedProductsList = ({ products }) => {
  const formatPrice = (amount, currency) => {
    const price = (amount / 100).toFixed(2);
    const numberFormat = new Intl.NumberFormat(['en-GB'], {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
    });
    return numberFormat.format(price);
  };

  return (
    <ul className="w-full flex flex-col md:px-5 py-2">
      {products.map(p => (
        <li key={p.node.id} className="border-t-2 border-b-2 border-contrast px-2 py-8 flex">
          <div className="border-r-2 border-contrast w-3/4 pr-2 md:pr-0">
            <p className="font-semibold">{p.node.product.name}</p>
            <p>{p.node.product.description}</p>
          </div>
          <span className="font-semibold tracking-wider w-1/4 text-right">
            {formatPrice(p.node.unit_amount, p.node.currency)}
          </span>
        </li>
      ))}
    </ul>
  );
};

const OrderDetails = ({ orderDetails }) => {
  const data = useStaticQuery(graphql`
    query PurchasedGiftVouchers {
      prices: allStripePrice(filter: { active: { eq: true } }) {
        edges {
          node {
            id
            currency
            unit_amount
            product {
              id
              name
              description
            }
          }
        }
      }
    }
  `);

  const productsToShow = orderDetails && data.prices.edges.filter(p => (orderDetails.includes(p.node.id) ? p : null));

  return (
    <div className="px-4 md:px-10">
      {productsToShow && <PurchasedProductsList products={productsToShow} />}
      <p className="text-center mt-4">
        Something wrong? Get in touch at <span className="italic font-semibold">contact@jonnyballguitar.com</span> as
        soon as possible!
      </p>
    </div>
  );
};

const Success = props => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState(null);

  // Get checkoput session id from url query
  const checkoutSessionId =
    typeof window !== 'undefined' && props.location.search && props.location.search.substring(1, 11) === 'session_id'
      ? props.location.search.substring(12, props.location.search.length - 1)
      : null;

  const getOrderDetails = async sessionId => {
    const response = await axios.post('/.netlify/functions/get-order-details', sessionId);
    return response.data;
  };
  useEffect(async () => {
    setError(false);
    if (!checkoutSessionId) {
      setError(true);
    }
    if (checkoutSessionId && !orderDetails) {
      try {
        const response = await getOrderDetails(checkoutSessionId);
        setOrderDetails(response);
      } catch (err) {
        setError(true);
      }
    }
  }, []);

  return (
    <Layout>
      <SEO title="Success" />
      <HeadingSection heading="Success!" />
      <Divider />
      <div className="min-h-screen bg-primary-lighter">
        {!error ? (
          <>
            <PageIntro
              text="Thank you for you purchase! See below for details of your order."
              subtext="You will receive your gift voucher shortly. Please get in touch if you do not receive your voucher within 24 hours."
            />
            {orderDetails ? (
              <OrderDetails orderDetails={orderDetails} />
            ) : (
              <p className="text-center">Getting order details . . . .</p>
            )}
          </>
        ) : (
          <PageIntro text="Oops...something went wrong. Did you end up here by accident?" />
        )}
      </div>
    </Layout>
  );
};

export default Success;

PurchasedProductsList.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string.isRequired,
        product: PropTypes.shape({
          name: PropTypes.string.isRequired,
          description: PropTypes.string,
        }),
        unit_amount: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
      }),
    }),
  ),
};

OrderDetails.propTypes = {
  orderDetails: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string)]),
};

Success.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};
